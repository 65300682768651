import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FiCornerDownRight } from "react-icons/fi"
import { FaLongArrowAltRight } from "react-icons/fa"
import { getImageBySize, localePath } from "../../utils"
import Portal from "./Portal"
import { useKey, useTimeoutFn } from "react-use"
import Card from "./Card"
import Button from "./Button"

export default function PageOrProductPage({
  product,
  page,
  id,
  layout,
  locale,
}) {
  const data = useStaticQuery(graphql`
    {
      products: allStrapiProduct {
        nodes {
          sections {
            ... on STRAPI__COMPONENT_UI_PAGE_GALLERY {
              id
              products {
                path
                id
              }
              pages {
                path
              }
            }
          }
        }
      }
      pages: allStrapiPage {
        nodes {
          sections {
            ... on STRAPI__COMPONENT_UI_PAGE_GALLERY {
              id
              products {
                path
              }
              pages {
                path
              }
            }
          }
        }
      }
    }
  `)
  const { pages, products } = data

  const components = [...pages.nodes, ...products.nodes]
    .map(p => p.sections?.filter(s => s.pages || s.products))
    .filter(p => p?.length)
    .flat(1)
  let match

  if (product)
    match = components.filter(
      c => c.products && c.products.find(p => p.path === product.path)
    )
  if (page)
    match = components.filter(
      c => c.pages && c.pages.find(p => p.path === page.path)
    )
  const pageOrProductIndex = match.findIndex(m => m.id === id)

  if (page)
    return (
      <Card
        href={localePath(page)}
        header={
          page.cover?.ext === ".mp4" ? (
            <video
              className="h-40 lg:h-56 w-full object-cover"
              //src={page.attributes.cover.data[0].url}
              autoPlay
              muted
              loop
            />
          ) : (
            <div
              className="h-40 lg:h-56 w-full bg-cover bg-center hover:opacity-75 rounded-3xl transition-opacity duration-300 mb-2"
              style={{
                backgroundImage: `url(${getImageBySize(
                  page?.cover[0],
                  "small"
                )})`,
              }}
            />
          )
        }
        layout={layout}
        title={<>{page.title}</>}
        description={
          <>
            {page.catchphrases
              ? page.catchphrases.split("\n")[pageOrProductIndex] ||
                page.description
              : page.description}
          </>
        }
      >
        <Button
          id={`section-${id}`}
          href={page.path}
          variant={layout !== "mini" ? "arrow" : "default"}
          Icon={layout !== "mini" && FaLongArrowAltRight}
        >
          {locale === "fr" ? "Lire plus" : "Read more"}
        </Button>
      </Card>
    )

  if (product)
    return (
      <Card
        href={localePath(product)}
        header={
          product.photos.ext === ".mp4" ? (
            <video
              className="h-40 lg:h-56 w-full object-cover"
              //src={product.photos[0].url}
              autoPlay
              muted
              loop
            />
          ) : (
            <div
              className="h-40 lg:h-56 w-full bg-cover bg-center hover:opacity-75 rounded-3xl transition-opacity duration-300 mb-2"
              style={{
                backgroundImage: `url(${getImageBySize(
                  product.photos[0],
                  "small"
                )})`,
              }}
            />
          )
        }
        layout={layout}
        title={<>{product.name}</>}
        description={
          <div className="font-light leading-none lg:leading-tight text-sm">
            {product.catchphrases.data.catchphrases
              ? product.catchphrases.data.catchphrases.split("\n")[
                  pageOrProductIndex
                ] || product.catchphrase
              : product.catchphrase}
          </div>
        }
      >
        <Button
          id={`section-${id}`}
          href={product.path}
          variant={layout !== "mini" ? "arrow" : "default"}
          Icon={layout !== "mini" && FaLongArrowAltRight}
        >
          {locale === "fr" ? "Découvrir" : "Read more"}
        </Button>
      </Card>
    )
}
